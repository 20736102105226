<template>
    <div class="pageContainer targetPage">
        <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
            <div class="flexStart flex_1" style="flex-wrap:wrap;">
                查询类型:<el-select v-model="datatype" size="small" @change="getList">
                    <el-option label="企业性质" value="enter_type"></el-option>
                    <el-option label="岗位行业分布" value="job_type"></el-option>
                    <el-option label="薪资范围" value="salary"></el-option>
                    <el-option label="岗位投递" value="delivery"></el-option>
                    <el-option label="企业岗位排名" value="job_rank"></el-option>
                    <el-option label="更多统计开发中" value="waiting"></el-option>
                </el-select>

                &nbsp;&nbsp;&nbsp;&nbsp; 年份:<el-select v-model="datayear" size="small" @change="getList">
                    <el-option label="全部" value=""></el-option>
                    <el-option :label="year" :value="year" v-for="(year, i) in years" :key="i"></el-option>

                </el-select>


            </div>

            <div class="flexStart"
                style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">



            </div>
        </el-row>
        <div class="dividerBar"></div>

        <el-row>
            <div style="text-align: center;color: #2469f3; font-size: 25px; padding:10px">
                所有入驻企业数:{{ allData.ent_count }} | 入驻企业数:{{ allData.ent_count }} | 提供岗位数:{{ allData.job_count }} | 就业岗位数:{{
                    allData.post_count }}
            </div>
            <div>
                <div v-if="datatype == 'enter_type'" class="flexStartUp">
                    <div id="chart_enter_type" style="width: 50%; height: 700px; ">

                    </div>
                    <div style="width: 50%;">
                        <el-table :data="DataList" stripe border>
                            <el-table-column type="index" label="序号" width="80px" align="center"></el-table-column>
                            <el-table-column label="企业性质" prop="enter_type" min-width="100px"
                                align="center"></el-table-column>
                            <el-table-column label="企业数量" prop="num" min-width="100px" align="center"></el-table-column>
                        </el-table>
                    </div>
                </div>

                <div v-if="datatype == 'job_type'" class="flexStartUp">
                    <div id="chart_job_type" style="width: 50%; height: 700px; ">

                    </div>
                    <div style="width: 50%;">
                        <el-table :data="DataList" stripe border>
                            <el-table-column type="index" label="序号" width="80px" align="center"></el-table-column>
                            <el-table-column label="行业" prop="type_name" min-width="100px" align="center"></el-table-column>
                            <el-table-column label="岗位数" prop="num" min-width="100px" align="center"></el-table-column>
                            <el-table-column label="职位数" prop="need_num" min-width="100px" align="center"></el-table-column>
                        </el-table>
                    </div>
                </div>

                <div v-if="datatype == 'salary'" class="flexStartUp">
                    <div id="chart_salary" style="width: 50%; height: 700px; ">

                    </div>
                    <div style="width: 50%;">
                        <el-table :data="DataList" stripe border>
                            <el-table-column type="index" label="序号" width="80px" align="center"></el-table-column>
                            <el-table-column label="薪资范围" prop="param_desc" min-width="100px"
                                align="center"></el-table-column>
                            <el-table-column label="岗位数" prop="num" min-width="100px" align="center"></el-table-column>

                        </el-table>
                    </div>
                </div>

                <div v-if="datatype == 'delivery'" class="flexStartUp">
                    <div id="chart_delivery" style="width: 50%; height: 700px; ">

                    </div>
                    <div style="width: 50%;">
                        <el-table :data="DataList" stripe border>
                            <el-table-column type="index" label="序号" width="80px" align="center"></el-table-column>
                            <el-table-column label="专业名称" prop="major_name" min-width="100px"
                                align="center"></el-table-column>
                            <el-table-column label="投递次数" prop="num" min-width="100px" align="center"></el-table-column>
                        </el-table>
                    </div>
                </div>

                <div v-if="datatype == 'job_rank'" class="flexStartUp">
                    <div id="chart_job_rank" style="width: 50%; height: 700px; ">

                    </div>
                    <div style="width: 50%;">
                        <el-table :data="DataList" stripe border>
                            <el-table-column type="index" label="序号" width="80px" align="center"></el-table-column>
                            <el-table-column label="企业名称" prop="firm_name" min-width="100px"
                                align="center"></el-table-column>
                            <el-table-column label="岗位数" prop="num" min-width="100px" align="center"></el-table-column>
                            <el-table-column label="招聘人数" prop="need_num" min-width="100px"
                                align="center"></el-table-column>
                        </el-table>
                    </div>
                </div>



            </div>
        </el-row>

    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {
            datatype: "salary",
            datayear: "",
            DataList: [],
            allData: {},
            years: []
        }
    },
    mounted() {
        let years = [];
        let nyear = new Date().getFullYear();
        for (let i = 0; i < 10; i++) {
            years.push(nyear--)
        }
        this.years = years
        this.getAll()
        this.getList()
    },
    methods: {
        getAll() {
            this.$http.post("/api/ent_tj_data", {
                datatype: "all",
                datayear: this.datayear
            }).then(res => {
                this.allData = res.data

            })
        },
        getList() {
            this.$http.post("/api/ent_tj_data", {
                datatype: this.datatype,
                datayear: this.datayear
            }).then(res => {
                this.initChart(res.data)

            })
        },
        initChart(data) {
            if (this.datatype == "enter_type") {
                this.DataList = data
                this.chart_entertype("chart_enter_type")
            } else if (this.datatype == "job_type") {
                this.DataList = data
                this.chart_jobtype("chart_job_type")
            } else if (this.datatype == "salary") {
                this.DataList = data
                this.chart_salary("chart_salary")
            } else if (this.datatype == "delivery") {
                this.DataList = data
                this.chart_delivery("chart_delivery")
            } else if (this.datatype == "job_rank") {
                this.DataList = data
                this.chart_job_rank("chart_job_rank")
            }
        },
        chart_entertype(chart_name) {
            let xData = this.DataList.map(e => { return { name: e.enter_type, value: e.num } })
            let option = {
                title: {
                    text: '',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                // legend: {
                //     top: "10%",
                //     left: 'center'
                // },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['30%', '50%'],
                        avoidLabelOverlap: true,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            normal: {
                                formatter: '{b}{c}人({d}%)',
                                textStyle: {
                                    fontWeight: 'normal',
                                    fontSize: 12
                                }
                            }
                        },
                        data: [

                        ],
                        color: [
                            '#1598ED', '#45BDFF',
                            '#37A2DA', '#32c5e9', '#67E0E3', '#9FE6B8',
                            '#7827e6', '#8d39ec', '#aa4ff6', '#ea80fc',
                            '#c848b9', '#f962a7', '#fd836d', '#f7ba79',
                            '#028174', '#0ab68b', '#92de88', '#ffe3b3',
                            '#294D99', '#156ACF',
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };


            option.series[0].name = "企业类型统计"
            option.series[0].data = xData;
            option.title.text = "企业类型比例"
            this.$echarts.init(document.getElementById(chart_name)).setOption(option);
        },
        chart_jobtype(chart_name) {
            let xData = this.DataList.map(e => { return { name: e.type_name, value: e.num } })
            let option = {
                title: {
                    text: '',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                // legend: {
                //     top: "10%",
                //     left: 'center'
                // },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['30%', '50%'],
                        avoidLabelOverlap: true,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            normal: {
                                formatter: '{b}{c}({d}%)',
                                textStyle: {
                                    fontWeight: 'normal',
                                    fontSize: 12
                                }
                            }
                        },
                        data: [

                        ],
                        color: [
                            '#1598ED', '#45BDFF',
                            '#37A2DA', '#32c5e9', '#67E0E3', '#9FE6B8',
                            '#7827e6', '#8d39ec', '#aa4ff6', '#ea80fc',
                            '#c848b9', '#f962a7', '#fd836d', '#f7ba79',
                            '#028174', '#0ab68b', '#92de88', '#ffe3b3',
                            '#294D99', '#156ACF',
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };


            option.series[0].name = "职位所属行业统计"
            option.series[0].data = xData;
            option.title.text = "职位所属行业比例"
            this.$echarts.init(document.getElementById(chart_name)).setOption(option);
        },
        chart_salary(chart_name) {
            let xData = this.DataList.map(e => { return { name: e.param_desc, value: e.num } })
            let option = {
                title: {
                    text: '',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                // legend: {
                //     top: "10%",
                //     left: 'center'
                // },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['30%', '50%'],
                        avoidLabelOverlap: true,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            normal: {
                                formatter: '{b}{c}({d}%)',
                                textStyle: {
                                    fontWeight: 'normal',
                                    fontSize: 12
                                }
                            }
                        },
                        data: [

                        ],
                        color: [
                            '#1598ED', '#45BDFF',
                            '#37A2DA', '#32c5e9', '#67E0E3', '#9FE6B8',
                            '#7827e6', '#8d39ec', '#aa4ff6', '#ea80fc',
                            '#c848b9', '#f962a7', '#fd836d', '#f7ba79',
                            '#028174', '#0ab68b', '#92de88', '#ffe3b3',
                            '#294D99', '#156ACF',
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };


            option.series[0].name = "职位薪资范围统计"
            option.series[0].data = xData;
            option.title.text = "职位薪资范围比例"
            this.$echarts.init(document.getElementById(chart_name)).setOption(option);
        },
        chart_delivery(chart_name) {

            let dataAxis = this._lo.map(this.DataList, 'major_name')
            let data = this._lo.map(this.DataList, 'num')


            let option = {
                title: {
                    text: '不同专业岗位投递次数统计',
                },
                grid: { containLabel: true, },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                    },
                    formatter: function (params) {

                        if (params.length > 0) {
                            var result = params[0].name + "<br>";
                            params.forEach(function (item) {
                                result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}</br>`
                            });
                            return result;
                        }
                    },
                },
                xAxis: {
                    data: dataAxis,
                    axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
                    axisTick: { show: true },
                    axisLine: { show: true },
                    z: 10
                },
                yAxis: {
                    min: 0,//最小值
                    axisLine: { show: true },
                    axisTick: { show: true },
                    splitLine: {
                        show: false
                    },
                    axisLabel: { color: '#000' }
                },
                series: [
                    {
                        type: 'bar',
                        barMaxWidth: 30,
                        itemStyle: {
                            color: '#33a9ff',

                            // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            //   { offset: 0, color: '#83bff6' },
                            //   { offset: 0.5, color: '#188df0' },
                            //   { offset: 1, color: '#188df0' }
                            // ])
                        },
                        emphasis: {
                            itemStyle: {
                                color: '#33a9ff',

                                // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                //   { offset: 0, color: '#2378f7' },
                                //   { offset: 0.7, color: '#2378f7' },
                                //   { offset: 1, color: '#83bff6' }
                                // ])
                            }
                        },
                        data: data,

                        label: {
                            show: true,
                            position: 'inside',
                        },
                    }
                ],
            };

            let myChart = this.$echarts.init(document.getElementById(chart_name));
            myChart.setOption(option)

        }, 
        chart_job_rank(chart_name) {

            let dataAxis = this._lo.map(this.DataList, 'firm_name')
            let data = this._lo.map(this.DataList, 'need_num')


            let option = {
                title: {
                    text: '企业岗位前10排名',
                },
                grid: { containLabel: true, },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                    },
                    formatter: function (params) {

                        if (params.length > 0) {
                            var result = params[0].name + "<br>";
                            params.forEach(function (item) {
                                result += `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#33a9ff;"></span>${item.value}</br>`
                            });
                            return result;
                        }
                    },
                },
                xAxis: {
                    data: dataAxis,
                    axisLabel: { inside: false, color: '#000', alignWithLabel: true, rotate: 60 },
                    axisTick: { show: true },
                    axisLine: { show: true },
                    z: 10
                },
                yAxis: {
                    min: 0,//最小值
                    axisLine: { show: true },
                    axisTick: { show: true },
                    splitLine: {
                        show: false
                    },
                    axisLabel: { color: '#000' }
                },
                series: [
                    {
                        type: 'bar',
                        barMaxWidth: 30,
                        itemStyle: {
                            color: '#33a9ff',

                            // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            //   { offset: 0, color: '#83bff6' },
                            //   { offset: 0.5, color: '#188df0' },
                            //   { offset: 1, color: '#188df0' }
                            // ])
                        },
                        emphasis: {
                            itemStyle: {
                                color: '#33a9ff',

                                // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                //   { offset: 0, color: '#2378f7' },
                                //   { offset: 0.7, color: '#2378f7' },
                                //   { offset: 1, color: '#83bff6' }
                                // ])
                            }
                        },
                        data: data,

                        label: {
                            show: true,
                            position: 'inside',
                        },
                    }
                ],
            };

            let myChart = this.$echarts.init(document.getElementById(chart_name));
            myChart.setOption(option)

        },
    }
}
</script>